import * as React from "react"
import Layout from "../../components/layout"
import Servicelinks from "../../components/servicelinks"
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '-90px',
    threshold: 0.3 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};


const fullWidth = {
	width: "100%",
}

const AppDev = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="AppDev">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
<h1><span className="revealblack">Mobile App Development</span></h1>
<p><span className="revealblack">We combine the flexibility and enjoy pushing app development ideas to enhance app user experiences on any devices.</span></p>
          <a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/featured-app-development.jpg'} alt="App Development" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">

        
<h2>Web app development</h2>
<p>Today's web and mobile applications have a strongly business impact on the way businesses and customers do business. Be it for your internal portal app or cloud-based web application solutions help to server or manage your client.</p>
<p>With a strong combination of front-end and back-end development technologies, we follow designs that are support with high-quality code. All are based on our wide pool of technology experience to work with, including Javascript Frameworks and PHP.</p>
<h2>Be responsive. Web browsing is no longer a desktop only experience. With mobile responsive, we build mobile app and websites to fit all sizes and shapes.</h2>
<p>Mobile responsive web development is an approach that allows a website to break itself down smoothly across multiple monitor sizes and platforms, be it a computer, tablet or mobile device. This allows for the site to be optimised for each platform, both in navigation, readability and load time.</p>
<p>Smartphones such iPhone and the Android make up one of the fastest growing modes for browsing websites and use of applications. To reach all your audience, your website and web application needs to function across mobile, tablet and desktop screen resolutions.</p>


          <img className="picture" src={'/img/services/responsive.svg'} alt="Website responsive" style={fullWidth} />


      </div>
       <Servicelinks></Servicelinks>
   </Section>
</Layout>

  </>
  )
}

export default AppDev


export const Head = () => (
  <>
    <title>App Development in Melbourne - Tobstar</title>
<meta name="description" content="We brand, strategise, design and app development powerful applications on the major mobile and tablet operating systems, Apple’s iOS and Google’s Android." />
  </>
)